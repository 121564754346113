<template>
    <div class='d-flex align-items-center h-100'>
         <div class='stap-inner' v-if="clienten && clienten.length > 0">
              <h1><span class='stap-number'>1.</span> Voor wie is het?</h1>
              <h5 class='stap-beschrijving'>
                Kies een gezinslid om aan te melden voor een cursus,<br>
                je kunt één gezinslid per keer aanmelden.
              </h5>
              <div class="row mt-5">
                  <div class="col-md-12 d-block">
                          <div
                              v-for="client in clienten" :key="client.customerId"
                              class='card-option mb-2 float-start'
                              @click='setClient($event, client)'
                              :class="isSelected(client.customerId)">
                              {{client.firstname}}
                          </div>
                      </div>
              </div>
        </div>
    </div>
</template>
<script>
export default {
  name: 'StapPersoon',
  computed: {
    clienten () {
      return this.$store.state.familie.info.clients
    },
    fullfill () {
      return !!this.$store.state.aanmelding.client
    }
  },
  methods: {
    setClient: function (e, client) {
      this.loading = true
      this.$store.dispatch('aanmelding/setClient', client)
      this.$router.push({ name: 'StapActiviteit' })
      this.loading = false
    },
    isSelected: function (id) {
      return {
        selected: this.$store.state.aanmelding.client && this.$store.state.aanmelding.client.customerId === id
      }
    }
  },
  mounted () {
    window.scrollTo(0, 0)
    this.$store.dispatch('familie/getFamilie')
  }
}
</script>

<style scoped>

</style>
